import React from 'react';
import { IconButton, MenuItem, TableCell } from '@mui/material';
import { useTranslation } from 'react-i18next';
import DeleteIcon from '@mui/icons-material/Delete';
import TextField from '../../../../../../components/TextField';
import TooltipComponent from '../../../../../../components/Tooltip';
import { SplitTargetType } from '../../../../../../types/splitLinks';
import { linkValidationSettings } from '../../../../util';

type PropsType = {
  target: SplitTargetType;
  isDeleteEnabled: boolean;
  validUrl: (value: string) => boolean;
  onChangeUrl: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    id: string
  ) => void;
  onChangeWeight: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    id: string
  ) => void;
  onChangeStatusCode: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    id: string
  ) => void;
  onDelete: (target: SplitTargetType) => void;
};
function InputFields({
  target,
  isDeleteEnabled,
  validUrl,
  onChangeUrl,
  onChangeWeight,
  onChangeStatusCode,
  onDelete,
}: PropsType) {
  const { t } = useTranslation();
  const [url, setUrl] = React.useState(target.url);
  const [weight, setWeight] = React.useState(target.weight);
  const [statusCode, setStatusCode] = React.useState(target.statusCode);
  const weightError = weight < 0 || !Number.isInteger(weight as number);

  const validateUrl = React.useCallback(
    (curentUrl: string) => {
      if (curentUrl.length === 0) return null;

      if (!validUrl(curentUrl)) return t('splitlink.splitTarget.pleaseValid');

      if (
        curentUrl.length >
        linkValidationSettings.splitLink.splitTarget.url.maxLength
      )
        return t('splitlink.splitTarget.urlError');

      return null;
    },
    [t, validUrl]
  );

  const urlError = validateUrl(url);

  return (
    <>
      <TableCell style={{ verticalAlign: ' baseline' }}>
        <TextField
          error={!!urlError}
          type="text"
          variant="outlined"
          fullWidth
          value={url}
          onChange={(event) => {
            setUrl(event.target.value);
            if (event.target.value.length > 0) onChangeUrl(event, target.id);
          }}
          helperText={urlError}
          placeholder={t('splitlink.splitTarget.pleaseValid')}
        />
      </TableCell>
      <TableCell style={{ verticalAlign: ' baseline' }}>
        <TextField
          type="number"
          variant="outlined"
          fullWidth
          error={weightError}
          helperText={weightError && t('splitlink.splitTarget.invalidWeight')}
          value={weight}
          onChange={(event) => {
            setWeight(parseInt(event.target.value, 10));
            onChangeWeight(event, target.id);
          }}
        />
      </TableCell>
      <TableCell style={{ verticalAlign: ' baseline' }}>
        <TextField
          fullWidth
          isSelect
          value={statusCode}
          onChange={(event) => {
            setStatusCode(event.target.value);
            onChangeStatusCode(event, target.id);
          }}
        >
          <MenuItem value="301">{t('splitlink.splitTarget.301')}</MenuItem>
          <MenuItem value="302">{t('splitlink.splitTarget.302')}</MenuItem>
        </TextField>
      </TableCell>
      <TableCell>
        <TooltipComponent
          label={t('splitlink.splitTarget.deleteTarget')}
          placement="right"
        >
          <span>
            <IconButton
              aria-label="deleteTarget"
              onClick={() => onDelete(target)}
              className="btn-with-icon"
              type="button"
              data-tip
              data-for="deleteTarget"
              color="primary"
              disabled={!isDeleteEnabled}
            >
              <DeleteIcon style={{ fontSize: '1.3rem' }} />
            </IconButton>
          </span>
        </TooltipComponent>
      </TableCell>
    </>
  );
}

export default InputFields;
