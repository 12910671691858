import React from 'react';
import {
  TextField as RawTextField,
  TextFieldProps,
  BaseTextFieldProps,
} from '@mui/material';

interface FocusInputProps {
  label?: string;
  isSelect?: boolean;
}

function TextField({
  label,
  placeholder,
  isSelect,
  ...rest
}: FocusInputProps & TextFieldProps & BaseTextFieldProps) {
  return (
    <RawTextField
      select={isSelect}
      size="small"
      label={label}
      variant="outlined"
      placeholder={placeholder}
      fullWidth
      {...rest}
    />
  );
}

export default TextField;
