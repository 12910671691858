import React from 'react';
import ReactNotification from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import LinkSplitterRoutes from './router';

function App() {
  return (
    <>
      <ReactNotification />
      <LinkSplitterRoutes />
    </>
  );
}

export default App;
