import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import InfoIcon from '@mui/icons-material/Info';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { styled } from '@mui/material/styles';
import Tooltip from '../../../../components/Tooltip';
import Modal from '../../../../components/Modal';
import Pagination from '../../../../components/Pagination';
import { usePagination } from '../../../../hooks/usePagination';

import InputFields from './compnents/InputFields';
import { linkValidationSettings } from '../../util';

function SplitTarget({ targets, onChange, onUpdate, hasChanges, setErrors }) {
  const [clickedDelete, setClickedDelete] = React.useState(false);
  const [deleteIndex, setDeleteIndex] = React.useState();

  const { t } = useTranslation();

  const { currentItems, currentPage, onPageChange, totalPages, itemsPerPage, onItemsPerPageChange } = usePagination(
    { items: targets }
  );

  const validUrl = (input) =>
    /^((http(s)?):\/\/)(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9](\?)?(.*)$/.test(
      input
    );

  // Check if target URLs are valid
  React.useEffect(() => {
    let validUrls = 0;

    targets.forEach((target) => {
      if (target.url.length > 0 && validUrl(target.url)) {
        validUrls += 1;
      }
    });
    if (validUrls !== targets.length) {
      setErrors(1);
    } else {
      setErrors(0);
    }
  }, [targets, setErrors]);

  const handleChangeUrl = (event, id) => {
    event.preventDefault();
    const url = event.target.value;
    if (
      validUrl(url) &&
      url.length < linkValidationSettings.splitLink.description.maxLength
    ) {
      setErrors(0);
    } else {
      setErrors(1);
    }
    hasChanges(true);

    onChange(id, 'url', url);
  };

  const handleChangeWeight = (event, id) => {
    event.preventDefault();
    const weight = event.target.value;
    hasChanges(true);
    onChange(id, 'weight', weight === '' ? 0 : weight);
  };

  const handleChangeStatusCode = (event, id) => {
    event.preventDefault();
    const status = event.target.value;
    hasChanges(true);
    onChange(id, 'statusCode', status);
  };

  const deleteTarget = (id) => {
    const splitData = targets.filter(
      (_target) => _target.id !== (id || deleteIndex)
    );
    onUpdate(splitData);
    setClickedDelete(false);
    setDeleteIndex('');
    hasChanges(true);
  };

  const cancelDelete = () => {
    setClickedDelete(false);
  };

  const onDelete = (target) => {
    setDeleteIndex(target.id);
    if (target.url.length > 0) {
      setClickedDelete(true);
    } else {
      deleteTarget(target.id);
    }
  };

  const StyledTableRow = React.useMemo(
    () =>
      styled(TableRow)(() => ({
        borderBottom: '1px solid rgba(224, 224, 224, 1)',
        '&:nth-of-type(odd)': {
          backgroundColor: '#f7fafc',
        },
        '&:first-of-type': {
          borderTop: '1px solid rgba(224, 224, 224, 1)',
        },
        '&:last-child td, &:last-child th': {
          border: 0,
        },
      })),
    []
  );

  const labelValue = React.useMemo(
    () => (
      <>
        <strong>{t('splitlink.splitTarget.301')}</strong>
        <br />
        <span>{t('splitlink.splitTarget.redirection301')}</span>
        <br />
        <br />
        <strong>{t('splitlink.splitTarget.302')}</strong>
        <br />
        <span>{t('splitlink.splitTarget.redirection302')}</span>
      </>
    ),
    [t]
  );

  return (
    <>
      <Box display="flex" flexDirection="column">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                sx={{ fontWeight: 'bold', color: '#8898aa' }}
                className="wide-col"
              >
                <Box display="flex" alignItems="center">
                  <span>{t('splitlink.splitTarget.targetlink')}</span>
                  <Tooltip
                    placement="top"
                    label={t('splitlink.splitTarget.labelTargetlink')}
                  >
                    <InfoIcon
                      fontSize="small"
                      icon={faInfoCircle}
                      data-tip
                      data-for="infoLink"
                    />
                  </Tooltip>
                </Box>
              </TableCell>
              <TableCell
                sx={{ fontWeight: 'bold', color: '#8898aa' }}
                className="small-col"
              >
                <Box display="flex" alignItems="center">
                  <span>{t('splitlink.splitTarget.weight')}</span>
                  <Tooltip
                    placement="top"
                    label={t('splitlink.splitTarget.labelWeight')}
                  >
                    <InfoIcon
                      fontSize="small"
                      icon={faInfoCircle}
                      data-tip
                      data-for="infoWeight"
                    />
                  </Tooltip>
                </Box>
              </TableCell>
              <TableCell sx={{ fontWeight: 'bold', color: '#8898aa' }}>
                <Box display="flex" alignItems="center">
                  <span>{t('splitlink.splitTarget.statusCode')}</span>
                  <Tooltip placement="top" label={labelValue}>
                    <InfoIcon fontSize="small" icon={faInfoCircle} />
                  </Tooltip>
                </Box>
              </TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {currentItems.map((target) => (
              <StyledTableRow key={`${target.id}`}>
                <InputFields
                  target={target}
                  validUrl={validUrl}
                  onChangeUrl={handleChangeUrl}
                  onChangeWeight={handleChangeWeight}
                  onChangeStatusCode={handleChangeStatusCode}
                  onDelete={onDelete}
                  isDeleteEnabled={currentItems.length > 1}
                />
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
        <Box display="flex" justifyContent="flex-end">
          <Pagination
            currentPage={currentPage}
            onPageChange={onPageChange}
            totalPages={totalPages}
            withItemsPerPage
            itemsPerPage={itemsPerPage}
            onItemsPerPageChange={onItemsPerPageChange}
            listLength={targets.length}
          />
        </Box>
      </Box>
      <Modal
        isOpen={clickedDelete}
        onCancel={cancelDelete}
        onConfirm={() => deleteTarget(null)}
        confirmColor="error"
        confirmText={t('splitlink.delete.confirmDeleteButton')}
        cancelText={t('splitlink.delete.cancel')}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <p>
            <InfoOutlinedIcon color="warning" style={{ fontSize: 100 }} />
          </p>
          <p style={{ fontSize: 25 }}>{t('splitlink.delete.areYouSure')}</p>
          <p>{t('splitlink.splitTarget.confirmDeleteTarget')}</p>
        </div>
      </Modal>
    </>
  );
}

export default SplitTarget;
