import { apiName } from '../../apiName';
import { SplitTargetType } from '../../types/splitLinks';
import { APIFetchSplit } from '../api';

export type GetSplitArgsType = {
  domain: string;
  split: string;
  sessionToken?: string;
};

type ResponseType = {
  statusCode: number;
  body: {
    content: SplitTargetType[];
    description: string;
  };
};
type GetSplitType = ({
  domain,
  split,
  sessionToken,
}: GetSplitArgsType) => Promise<ResponseType>;

const getSplit: GetSplitType = async ({ domain, split, sessionToken }) =>
  new Promise((resolve, reject) => {
    if (!sessionToken) {
      reject(new Error('missing token'));
      return;
    }
    const path = `/domains/${domain}/splits/${split}`;
    APIFetchSplit(apiName + path, sessionToken)
      .then((response) => {
        resolve(response);
      })
      .catch((e) => {
        reject(e);
      });
  });
export default getSplit;
