import React, { createContext, useReducer, useMemo } from 'react';
import { modalActionType } from '../types';

import { modalProps, modalStateType, IChildren } from './types';

const initialState = {
  isNew: false,
  isEdit: false,
};

export const ModalContext = createContext<modalProps>({
  state: initialState,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  dispatch: () => {},
});

const modalContextReducer = (
  state: modalStateType,
  action: modalActionType
) => {
  switch (action.type) {
    case 'NEW':
      return { ...state, isNew: action.payload };
    case 'EDIT':
      return { ...state, isEdit: action.payload };
    default:
      throw new Error('Unhandled action');
  }
};

// eslint-disable-next-line react/function-component-definition
const ModalProvider = ({ children }: IChildren) => {
  const [state, dispatch] = useReducer(modalContextReducer, initialState);
  const value = useMemo(() => ({ state, dispatch }), [state, dispatch]);

  return (
    <ModalContext.Provider value={value}>{children}</ModalContext.Provider>
  );
};

export default ModalProvider;
