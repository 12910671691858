/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Box, IconButton } from '@mui/material';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate, useParams } from 'react-router-dom';
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import SplitTarget from '../SplitTarget';
import GenerateSplitKeyButton from '../../../../components/Buttons/GenerateSplitKeyButton';
import 'react-notifications-component/dist/theme.css';
import Tooltip from '../../../../components/Tooltip';
import { addNotification } from '../../../../utils/notification';
import Modal from '../../../../components/Modal';
import TextField from '../../../../components/TextField';
import { filter } from '../../../../utils/arrays';
import { useLoading } from '../../../../hooks/useLoading';
import useApi from '../../../../api';
import useAuth from '../../../../hooks/useAuth';
import { SplitTargetType } from '../../../../types/splitLinks';
import { linkValidationSettings } from '../../util';

const emptySplit: () => SplitTargetType = () => ({
  url: '',
  weight: 1,
  statusCode: '301',
  id: uuidv4(),
});

type PropsTypes = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  splitName: string | null;
  domainName: string;
  onSaveSplit: (
    splitName: string,
    splitData: SplitTargetType[],
    domainName: string,
    splitDescription: string
  ) => Promise<boolean | void>;
  onCopy: (splitlink: string) => void;
  onPutSplit: (
    splitName: string,
    splitData: SplitTargetType[],
    domainName: string,
    splitDescription: string
  ) => Promise<boolean | void>;
  splits: string[];
};

function SplitModal({
  isOpen,
  setIsOpen,
  splitName,
  domainName,
  onSaveSplit,
  onPutSplit,
  onCopy,
  splits,
}: PropsTypes) {
  const [split, setSplit] = useState('');
  const [splitDescription, setSplitDescription] = useState<string>('');
  const [targetErrors, setTargetErrors] = useState(0);

  const [splitErrors, setSplitErrors] = useState(splitName ? 0 : 1);
  const [canSave, setCanSave] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);
  const [wantsToClose, setWantsToClose] = useState(false);
  const { domainId } = useParams();
  const { dispatch } = useLoading();
  const { token } = useAuth();
  const { onGetSplit } = useApi(token);
  const [splitNameError, setSplitNameError] = useState<string | null>(null);
  const [splitDescriptionError, setSplitDescriptionError] = useState<
    string | null
  >(null);
  const [splitData, setSplitData] = useState<SplitTargetType[]>([]);

  const navigate = useNavigate();

  const splitLinkKeyRef = useRef<HTMLInputElement>(null);
  const splitDescriptionRef = useRef<HTMLInputElement>(null);

  const { t } = useTranslation();

  useEffect(() => {
    if (splitName !== null && !hasChanges) {
      setCanSave(false);
    } else if (
      splitErrors === 0 &&
      targetErrors === 0 &&
      splitDescriptionError === null
    ) {
      setCanSave(true);
    } else {
      setCanSave(false);
    }
  }, [targetErrors, splitErrors, hasChanges, splitDescriptionError]);

  useEffect(() => {
    if (splitName === '' || splitName === null) {
      setSplitErrors(1);
    } else {
      setSplitErrors(0);
    }
  }, [splitName, split]);

  const clearState = useCallback(() => {
    setSplitDescription('');
    setSplitData([emptySplit()]);
  }, [setSplitDescription]);

  const handleAdd = useCallback(() => {
    setSplitData((prev) => [...prev, emptySplit()]);
  }, [setSplitData]);

  useEffect(() => {
    if (splitName && isOpen) {
      const getData = async () => {
        const response = await onGetSplit({
          domain: domainId as string,
          split: splitName,
        });
        if (response.statusCode === 200) {
          const values = response.body.content;
          setSplitData(values.map((data) => ({ ...data, id: uuidv4() })));
          setSplitDescription(response.body.description || '');
          dispatch({ type: 'LOADING', payload: false });
        }
      };
      dispatch({ type: 'LOADING', payload: true });
      getData().catch((e) => console.error(e));
    } else {
      clearState();
    }
  }, [isOpen, splitName, clearState]);

  const updateTarget = (forDelete: any) => {
    const newSplitData = _.cloneDeep(forDelete);
    setSplitData(newSplitData);
  };

  const handleChange = <K extends keyof SplitTargetType>(
    id: string,
    type: K,
    value: any
  ) => {
    let val = value;
    if (type === 'weight') {
      val = parseInt(value, 10);
    }
    const newSplitData = splitData.map((target) => {
      if (target.id !== id) {
        return target;
      }
      const newTarget = { ...target, [type]: val };
      return newTarget;
    });
    setSplitData(newSplitData);
  };

  const checkForErrors = useCallback(() => {
    if (
      splitLinkKeyRef.current === null ||
      splitLinkKeyRef.current.value === ''
    ) {
      return;
    }
    setSplitNameError(null);
    const regex = linkValidationSettings.splitLink.name.regex;
    if (
      filter(splits as any).byName(splitLinkKeyRef.current.value).length > 0
    ) {
      setSplitNameError(
        `${t('splitlink.edit.splitlinkExists')},  ${t(
          'splitlink.edit.splitlinksUnique.'
        )}`
      );
      setSplitErrors(1);
      splitLinkKeyRef.current.classList.add('error');
    } else if (
      !splitLinkKeyRef.current.value.match(regex) ||
      splitLinkKeyRef.current.value.match(regex)!.length <
        splitLinkKeyRef.current.value.length
    ) {
      setSplitNameError(t('splitlink.edit.valueMustBeValid'));
      setSplitErrors(1);
      splitLinkKeyRef.current.classList.add('error');
    } else if (
      splitLinkKeyRef.current.value === undefined ||
      splitLinkKeyRef.current.value.length <
        linkValidationSettings.splitLink.name.minLength ||
      splitLinkKeyRef.current.value.length >
        linkValidationSettings.splitLink.name.maxLength ||
      splitLinkKeyRef.current.value === ''
    ) {
      setSplitNameError(t('splitlink.edit.splitKeylengthError'));
      setSplitErrors(1);
      splitLinkKeyRef.current.classList.add('error');
    } else {
      setSplitErrors(0);
      splitLinkKeyRef.current.classList.remove('error');
    }
  }, [splits, t]);

  const checkForDescriptionError = useCallback((value: string) => {
    if (value.length > linkValidationSettings.splitLink.description.maxLength) {
      return setSplitDescriptionError(t('splitlink.edit.descriptionError'));
    }
    return setSplitDescriptionError(null);
  }, []);

  const changeGeneratedSplitName = useCallback((splitHash: string) => {
    setSplit(splitHash);
    setHasChanges(true);
  }, []);

  const modalClose = () => {
    clearState();
    setSplit('');
    setIsOpen(false);
    setWantsToClose(false);
    setHasChanges(false);
    if (window.history.length >= 3) {
      return navigate(-1);
    }
    return navigate(`/domains/${domainId}/?page=1`);
  };

  const changeSplitName = useCallback(
    (event: { target: { value: string } }) => {
      if (splitName === null) {
        setSplit(event.target.value);
        setHasChanges(true);
      }
    },
    [splitName]
  );

  const changeDescription = useCallback(
    (event: { target: { value: string } }) => {
      setSplitDescription(event.target.value);
      setHasChanges(true);
      checkForDescriptionError(event.target.value);
    },
    []
  );

  const closeSplit = () => {
    if (hasChanges) {
      setWantsToClose(true);
    } else {
      modalClose();
    }
  };

  const onCancel = () => {
    setWantsToClose(false);
  };

  const saveSplitData = async () => {
    const successfullySaved = await onSaveSplit(
      split,
      splitData,
      domainName,
      splitDescription
    );

    if (successfullySaved) {
      setHasChanges(false);
      modalClose();
    } else {
      addNotification(
        t('splitlink.edit.savingFailed'),
        t('splitlink.edit.anErrorAdding')
      );
    }
  };

  const putSplitData = async () => {
    if (splitName) {
      const response = await onPutSplit(
        splitName,
        splitData,
        domainName,
        splitDescription
      );
      if (response) {
        modalClose();
      } else {
        addNotification(
          t('splitlink.edit.savingFailed'),
          t('splitlink.edit.errorSaving')
        );
      }
    }
  };

  useEffect(() => {
    if (hasChanges) checkForErrors();
  }, [checkForErrors, hasChanges, split]);

  const divStyle = {
    display: 'flex',
    gap: splitName === null ? 16 : 0,
    alignItems: 'start',
    margin: '1rem 0',
  };

  const renderSplitNameInput = () => {
    if (splitName === null || splitName === undefined) {
      return (
        <>
          <TextField
            style={{ flex: 1 }}
            type="text"
            size="small"
            variant="outlined"
            value={splitName !== null ? splitName : split}
            onChange={changeSplitName}
            inputRef={splitLinkKeyRef}
            disabled={splitName !== null}
            label={t('splitlink.new.pleaseEnter')}
            error={!!splitNameError}
            helperText={splitNameError}
          />
          {splitName === null && (
            <GenerateSplitKeyButton
              onSplitKeyChange={changeGeneratedSplitName}
            />
          )}
        </>
      );
    }

    return (
      <Box
        display="flex"
        flexDirection="row"
        gap="0.5rem"
        alignContent="center"
        justifyContent="center"
      >
        <span style={{ height: 'fit-content', alignSelf: 'center' }}>
          {splitName}
        </span>

        <Tooltip label={t('splitlink.splitLinksTable.copyURL')}>
          <IconButton
            aria-label="copy"
            onClick={() => onCopy('https://' + domainName + '/' + splitName)}
            className="btn-with-icon"
            data-tip
            color="primary"
            data-for="copySplit"
          >
            <FileCopyIcon style={{ fontSize: '1.5rem' }} />
          </IconButton>
        </Tooltip>
      </Box>
    );
  };

  return (
    <Modal
      confirmText={t('splitlink.edit.save')}
      cancelText={t('splitlink.edit.close')}
      onConfirm={splitName === null ? saveSplitData : putSplitData}
      onCancel={closeSplit}
      isConfirmDisabled={!canSave}
      isOpen={isOpen}
      size="lg"
      hasDividers
      title={
        splitName !== null
          ? t('splitlink.edit.edit')
          : t('splitlink.edit.create')
      }
    >
      <form>
        <div style={divStyle}>
          <p
            className="dummy-label"
            style={{
              color: '#999',
              height: 40,
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {`https://${domainName}`}/
          </p>
          {renderSplitNameInput()}
        </div>
        <Box margin="2rem 0 1rem 0">
          <TextField
            style={{ flex: 1 }}
            type="text"
            size="small"
            variant="outlined"
            value={splitDescription}
            onChange={changeDescription}
            inputRef={splitDescriptionRef}
            label={t('splitlink.new.description')}
            error={!!splitDescriptionError}
            helperText={splitDescriptionError}
          />
        </Box>
        <SplitTarget
          targets={splitData}
          onChange={handleChange}
          onUpdate={updateTarget}
          setErrors={setTargetErrors}
          hasChanges={setHasChanges}
        />
        <div style={{ paddingTop: '1rem' }}>
          <Tooltip label={t('splitlink.edit.addTarget')}>
            <Button
              style={{ width: '100%' }}
              type="button"
              className="btn-style add-btn"
              onClick={handleAdd}
              data-tip
              data-for="addNewTarget"
              variant="contained"
            >
              <AddIcon /> {t('splitlink.splitTarget.buttonAddNew')}
            </Button>
          </Tooltip>{' '}
        </div>
      </form>
      <Modal
        confirmText={t('splitlink.edit.confirmText')}
        cancelText={t('splitlink.delete.cancel')}
        onConfirm={modalClose}
        onCancel={onCancel}
        confirmColor="error"
        isOpen={hasChanges && wantsToClose}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <p>
            <InfoOutlinedIcon color="primary" style={{ fontSize: 100 }} />
          </p>
          <p style={{ fontSize: 25 }}>
            {t('splitlink.edit.closeWithoutSaving')}
          </p>
          <p>{t('splitlink.edit.unsavedChanges')}</p>
        </div>
      </Modal>
    </Modal>
  );
}

export default SplitModal;
