
const wrapper = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "0.5rem 0",
    minHeight: "95vh",
}

const imageWrapper = {
    width: '60%',
    height: 'auto'
}

const imageStyle = { 
    width: '100%', 
    height: 'auto' 
}

const card = {
    position: "relative",
    backgroundColor: "white",
    gap: "0.5rem",
    padding: "3rem 1rem 3rem 1rem",
    borderRadius: "5px",
    border: "1px solid rgba(var(--card-border-rgb), 0)",
    width: "24vw",
    boxShadow: "1px 0px 5px 5px rgba(0, 0, 0, 0.2)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    minWidth: "400px"
}

const cardContentWrapper = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column"
}

const cardTitle = {
    fontWeight: "bold",
    color: "text.secondary",
    fontSize: "17px",
    marginTop: "20px"
}

const cardText = {
    color: "text.secondary",
    margin: "20px 0",
    fontSize: "16px"
}

const cardButton = {
    margin: 0,
    textTransform:'none',
    fontWeight:'bold',
    fontSize: '15px'
}

const languageSwitcherWrapper = { 
    position: 'absolute', 
    right: 20, 
    top: 20 
}

const documentationIconWrapper = { 
    position: 'absolute', 
    right: 20, 
    top: 60
}

const documentationIconButton = { 
    textTransform: 'none',
    padding: 0,
    minWidth: 0
}

export const styles = {
    wrapper,
    card,
    cardContentWrapper, 
    cardTitle, 
    cardText, 
    cardButton, 
    imageWrapper,
    imageStyle,
    languageSwitcherWrapper,
    documentationIconWrapper,
    documentationIconButton
} as const