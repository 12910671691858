import i18next from 'i18next';

export const formatDateEpoch = (date: string) => {
  if (date === undefined) {
    return '';
  }
  const parsedDate = new Date(parseInt(date, 10));

  const localeStringOptions: Intl.DateTimeFormatOptions  = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: '2-digit', 
    minute: '2-digit', 
    second: '2-digit'
  };

  return parsedDate.toLocaleString(i18next.language, localeStringOptions);
};
