/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useLoading } from '../../hooks/useLoading';
import SplitList from './components/SplitList';
import SplitModal from './components/SplitModal';
import useAuth from '../../hooks/useAuth';
import { useModal } from '../../hooks/useModal';
import { LoadingContext } from '../../contexts/loading';
import useQuery from '../../hooks/useQuery';
import { copyToClipboard } from '../../utils/clipboard';
import useApi from '../../api';
import { SplitTargetType } from '../../types/splitLinks';

function Links() {
  const query = useQuery();
  const { token, isAuthenticated } = useAuth();
  const { dispatch: modalDispatch } = useModal();
  const { domainId } = useParams();
  const { pathname } = useLocation();
  const {
    state: { loading },
  } = useContext(LoadingContext);
  const { onPutSplit, onSaveSplit, onGetAllSplits } = useApi(token);

  const getSplitFromUrl = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const splitFromUrl = urlParams.get('split');
    return splitFromUrl;
  };

  const [allSplitsData, setAllSplitsData] = useState([]);
  const [isSplitModalOpen, setIsSplitModalOpen] = useState(false);

  const [selectedSplitName, setSelectedSplitName] = useState(getSplitFromUrl());

  const { dispatch } = useLoading();

  const newSplitKey = query.get('new');

  // eslint-disable-next-line no-shadow
  const updateSplitList = () => {
    dispatch({ type: 'LOADING', payload: true });

    onGetAllSplits({ domain: domainId as string }).then((response: any) => {
      setAllSplitsData(response.body);
      dispatch({ type: 'LOADING', payload: false });
    });
  };

  const handleSaveSplit = (
    split: string,
    splitDataParam: SplitTargetType[],
    domainNameParam: string,
    splitDescriptionParam: string
  ) => {
    dispatch({ type: 'LOADING', payload: true });

    return onSaveSplit({
      domain: domainNameParam,
      splitName: split,
      splitData: splitDataParam,
      splitDescription: splitDescriptionParam,
    })
      .then((response: any) => {
        dispatch({ type: 'LOADING', payload: false });

        if (response.statusCode === 400) {
          return false;
        }
        if (response.statusCode === 200) {
          modalDispatch({ type: 'NEW', payload: true });
          updateSplitList();
          return true;
        }
        return false;
      })
      .catch(console.error);
  };

  const handlePutSplit = async (
    splitName: string,
    splitDataParam: any,
    domainNameParam: any,
    splitDescriptionParam: string
  ) => {
    dispatch({ type: 'LOADING', payload: true });

    const res = await onPutSplit({
      splitName,
      splitData: splitDataParam,
      domainName: domainNameParam,
      splitDescription: splitDescriptionParam,
    })
      .then((response: any) => {
        dispatch({ type: 'LOADING', payload: false });

        if (response.statusCode === 200) {
          updateSplitList();
          return true;
        }
        return false;
      })
      .catch(console.error);

    return res;
  };

  const getSplitsData = useCallback(() => {
    if (domainId !== '') {
      window.scrollTo(0, 0);
      dispatch({ type: 'LOADING', payload: true });
      onGetAllSplits({ domain: domainId as string })
        .then((response: any) => {
          setAllSplitsData(response.body ?? []);
          dispatch({ type: 'LOADING', payload: false });
        })
        .catch(console.error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [domainId, dispatch, token]);

  useEffect(() => {
    if (isAuthenticated) {
      getSplitsData();
    }
  }, [getSplitsData, domainId, token, isAuthenticated]);

  useEffect(() => {
    if (pathname.includes('/new') && !loading) {
      setIsSplitModalOpen(true);
    }
  }, [pathname, loading]);

  const showSplitModal = useCallback((splitName: string | null) => {
    setSelectedSplitName(splitName);
    setIsSplitModalOpen(true);
  }, []);

  useEffect(() => {
    if (newSplitKey) {
      showSplitModal(null);
    }
  }, [showSplitModal, newSplitKey]);

  return (
    <>
      <SplitList
        splits={allSplitsData}
        domainName={domainId as string}
        onOpenSplitModal={showSplitModal}
        onUpdateSplitList={updateSplitList}
      />
      <SplitModal
        isOpen={isSplitModalOpen}
        setIsOpen={setIsSplitModalOpen}
        splitName={selectedSplitName}
        domainName={domainId as string}
        onCopy={copyToClipboard}
        onSaveSplit={handleSaveSplit}
        onPutSplit={handlePutSplit}
        splits={allSplitsData}
      />
    </>
  );
}

export default Links;
