/* eslint-disable no-console */

import { ApiSplitTargetType } from '../types/splitLinks';

/**
 * @param {string} APIUrl
 * @param {string} sessionToken
 * @returns {Promise<Data[]>}
 */
export const APIFetchDomains = (APIUrl: string, sessionToken: string) => {
  const url = new URL(APIUrl);

  return fetch(url.href, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + sessionToken,
    },
  }).then((data) => data.json());
};

/**
 * @param {string} APIUrl
 * @param {string} sessionToken
 * @returns {Promise<Data[]>}
 */
export const APIFetchAllSplits = async (
  APIUrl: string,
  sessionToken: string
) => {
  const url = new URL(APIUrl);

  return fetch(url.href, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + sessionToken,
    },
  }).then((data) => data.json());
};

/**
 * @param {string} APIUrl
 * @param {string} sessionToken
 * @returns {Promise<Data[]>}
 */
export const APIFetchSplit = (APIUrl: string, sessionToken: string) => {
  const url = new URL(APIUrl);

  return fetch(url.href, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + sessionToken,
    },
  }).then((data) => data.json());
};

/**
 * @param {string} APIUrl
 * @param {string} splitName
 * @param {array} splitData
 * @param {string} domainName
 * @param {string} sessionToken
 * @returns {Promise<Data[]>}
 */
export const APIPutSplit = (
  APIUrl: string,
  splitName: string,
  splitData: ApiSplitTargetType[],
  splitDescription: string,
  domainName: string,
  sessionToken: string
) => {
  const url = new URL(APIUrl);
  const bodyData = {
    content: splitData,
    description: splitDescription,
  };

  return fetch(url.href, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + sessionToken,
    },
    body: JSON.stringify(bodyData),
  }).then((data) => data.json());
};

/**
 *
 * @param {string} APIUrl
 * @param {string} newSplitName
 * @param {array} newSplitData
 * @param {string} domain
 * @param {string} sessionToken
 * @returns {Promise<Data[]>}
 */
export const APIPostSplit = (
  APIUrl: string,
  newSplitName: string,
  splitData: ApiSplitTargetType[],
  splitDescription: string,
  domain: string,
  sessionToken: string
) => {
  const url = new URL(APIUrl);
  const bodyData = {
    content: splitData,
    description: splitDescription,
  };

  return fetch(url.href, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + sessionToken,
    },
    body: JSON.stringify(bodyData),
  }).then((data) => data.json());
};

/**
 *
 * @param {string} APIUrl
 * @param {string} sessionToken
 * @returns {Promise<Data[]>}
 */
export const APIDeleteSplit = (APIUrl: string, sessionToken: string) =>
  fetch(APIUrl, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + sessionToken,
    },
  }).then((data) => data.json());
