import { APIPutSplit } from '../api';
import { apiName } from '../../apiName';
import { SplitTargetType } from '../../types/splitLinks';

export type PutSplitArgsType = {
  splitName: string;
  splitData: SplitTargetType[];
  domainName: string;
  splitDescription: string;
  sessionToken?: string;
};
export default async function putSplit({
  splitName,
  splitData,
  domainName,
  splitDescription,
  sessionToken,
}: PutSplitArgsType) {
  return new Promise((resolve, reject) => {
    if (!sessionToken) {
      reject(new Error('missing token'));
      return;
    }
    const path = `/domains/${domainName}/splits/${splitName}`;
    APIPutSplit(
      apiName + path,
      splitName,
      splitData.map(({ id, ...rest }) => rest),
      splitDescription,
      domainName,
      sessionToken
    )
      .then((response) => {
        resolve(response);
      })
      .catch((e) => {
        reject(e);
      });
  });
}
