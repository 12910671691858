import * as React from 'react';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import { Breakpoint } from '@mui/material';

type Colors =
  | 'inherit'
  | 'primary'
  | 'secondary'
  | 'success'
  | 'error'
  | 'info'
  | 'warning';

type PropsTypes = {
  isOpen: boolean;
  onCancel: () => void;
  children: React.ReactNode;
  title?: string;
  onConfirm?: () => void;
  confirmText?: string;
  cancelText?: string;
  hasDividers?: boolean;
  confirmColor?: Colors;
  cancelColor?: Colors;
  size?: Breakpoint;
  isConfirmDisabled?: boolean;
  isCancelFocused?: boolean;
};

function Modal({
  children,
  onCancel,
  onConfirm,
  cancelColor,
  confirmColor,
  isOpen,
  cancelText = 'Cancel',
  confirmText = 'Confirm',
  hasDividers,
  title,
  isConfirmDisabled,
  size,
  isCancelFocused,
}: PropsTypes) {
  return (
    <Dialog fullWidth maxWidth={size} open={isOpen} onClose={onCancel}>
      {title && <DialogTitle>{title}</DialogTitle>}
      <DialogContent dividers={hasDividers}>{children}</DialogContent>
      {onConfirm && (
        <DialogActions style={{ paddingBottom: 32 }}>
          <Button
            autoFocus={isCancelFocused}
            color={cancelColor}
            onClick={onCancel}
          >
            {cancelText}
          </Button>
          <Button
            variant="contained"
            disabled={isConfirmDisabled}
            color={confirmColor}
            onClick={onConfirm}
          >
            {confirmText}
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
}

export default Modal;
