/* eslint-disable no-console */
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { store } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Box, Portal } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useLocation, useNavigate } from 'react-router-dom';
import Tooltip from '../../../../components/Tooltip';
import SplitlinksTable from '../SplitlinksTable';
import { filter, sortSplitlinks } from '../../../../utils/arrays';
import Modal from '../../../../components/Modal';
import useApi from '../../../../api';
import { SortType, SplitLinkDataType } from '../../../../types/splitLinks';
import useAuth from '../../../../hooks/useAuth';
import { useModal } from '../../../../hooks/useModal';
import { copyToClipboard } from '../../../../utils/clipboard';
import useResponsivity from '../../../../hooks/useResponsivity';

type PropsType = {
  splits: SplitLinkDataType[];
  domainName: string;
  onOpenSplitModal: (splitName: string | null) => void;
  onUpdateSplitList: (splitName: string) => void;
};

function SplitList({
  splits,
  domainName,
  onOpenSplitModal,
  onUpdateSplitList,
}: PropsType) {
  const { t } = useTranslation();

  const [clickedDelete, setClickedDelete] = useState(false);
  const [deleteSplitName, setDeleteSplitName] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [sortedColumn, setSortedColumn] = useState<SplitLinkDataType[]>([]);
  const [sortDirection, setSortDirection] = useState(true);
  const [sortType, setSortType] = useState<SortType>('changedAt');
  const { token } = useAuth();
  const { state, dispatch } = useModal();
  const { onDeleteSplit } = useApi(token);
  const navigate = useNavigate();
  const location = useLocation();
  const { isNew, isEdit } = state;

  const [isMobile] = useResponsivity();

  const handleSelect = (splitName: string) => {
    onOpenSplitModal(splitName);
    if (location.pathname.includes(splitName)) return;
    navigate(`/domains/${domainName}/?splitlink=${splitName}`);
  };

  const onBack = () => {
    navigate('/domains');
  };

  const onDelete = () => {
    onDeleteSplit({ domain: domainName, split: deleteSplitName }).then(() => {
      onUpdateSplitList(domainName);
      store.addNotification({
        title: 'Delete',
        message: 'Split Deleted!',
        type: 'danger',
        container: 'top-center',
        animationIn: ['animated', 'fadeIn'],
        animationOut: ['animated', 'fadeOut'],
        dismiss: {
          duration: 4000,
        },
      });
      setClickedDelete(false);
      setDeleteSplitName('');
    });
  };

  const cancelDelete = () => {
    setClickedDelete(false);
  };

  const deleteSplitLink = (splitName: string) => {
    setClickedDelete(true);
    setDeleteSplitName(splitName);
  };

  const handleNewSplitModal = () => {
    onOpenSplitModal(null);
    navigate(`/domains/${domainName}/?new=1`);
  };

  const handleSort = useCallback(
    (sortTypeParams: SortType) => {
      if (sortType !== sortTypeParams) {
        setSortType(sortTypeParams);
        setSortDirection(false);
      } else {
        setSortDirection((old) => !old);
      }
    },
    [sortType]
  );

  useEffect(() => {
    setSortedColumn(filter(splits).byText(searchValue));
  }, [searchValue, splits]);

  useEffect(() => {
    if (sortedColumn.length > 0) {
      if (isNew) {
        setSortedColumn((prev) =>
          sortSplitlinks([...prev], 'changedAt').alphabeticallyDesc()
        );
        setSortDirection(true);
        setSortType('changedAt');
        dispatch({ type: 'NEW', payload: false });
        return;
      }

      if (!isNew && !isEdit) {
        if (sortDirection) {
          setSortedColumn((prev) =>
            sortSplitlinks([...prev], sortType).alphabeticallyDesc()
          );
        } else if (!sortDirection) {
          setSortedColumn((prev) =>
            sortSplitlinks([...prev], sortType).alphabetically()
          );
        }
      }
    }
  }, [
    dispatch,
    isEdit,
    isNew,
    sortDirection,
    sortType,
    sortedColumn.length,
    splits,
  ]);

  return (
    <>
      <div>
        <Button
          variant="text"
          type="button"
          style={{
            textTransform: 'none',
            fontWeight: '500',
            fontSize: '28px',
          }}
          onClick={onBack}
          startIcon={<ArrowBackIcon />}
        >
          {t('splitlink.splitList.splitlinks')}
        </Button>
        {domainName !== '' ? (
          <Box>
            <div className="footer-controls">
              <Tooltip label={t('splitlink.splitList.newSplit')}>
                <Button
                  size={isMobile ? 'small' : 'large'}
                  variant="contained"
                  className="btn-style add-btn"
                  onClick={() => handleNewSplitModal()}
                  data-tip
                  style={{ width: '100%' }}
                  data-for="addSplit"
                >
                  <AddIcon /> {t('splitlink.splitList.buttonAddNew')}
                </Button>
              </Tooltip>
            </div>
          </Box>
        ) : (
          ''
        )}
        <SplitlinksTable
          splitlinks={sortedColumn}
          sortDirection={sortDirection}
          sortType={sortType}
          domainName={domainName}
          searchValue={searchValue}
          onSelect={handleSelect}
          onDelete={deleteSplitLink}
          onCopy={copyToClipboard}
          onSort={handleSort}
          onChangeSearch={setSearchValue}
        />
      </div>
      {clickedDelete ? (
        <Portal>
          <Modal
            isOpen={clickedDelete}
            confirmText={t('splitlink.delete.confirmDeleteButton')}
            cancelText={t('splitlink.delete.cancel')}
            confirmColor="error"
            onConfirm={onDelete}
            onCancel={cancelDelete}
            isCancelFocused
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <p>
                <InfoOutlinedIcon color="warning" style={{ fontSize: 100 }} />
              </p>
              <p style={{ fontSize: 25 }}>{t('splitlink.delete.areYouSure')}</p>
              <p>{t('splitlink.splitList.confirmDelete')}</p>
            </div>
          </Modal>
        </Portal>
      ) : null}
    </>
  );
}

export default SplitList;
