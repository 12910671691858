import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import { generateRandomString } from '../../../utils/strings';

function GenerateSplitKeyButton({ onSplitKeyChange }) {
  const { t } = useTranslation();
  const [hash, setHash] = useState('');

  const generateHash = (e) => {
    e.preventDefault();
    setHash(generateRandomString(16));
  };

  useEffect(() => {
    if (hash.length > 0) {
      onSplitKeyChange(hash);
    }
  }, [hash, onSplitKeyChange]);

  return (
    <Button
      size="small"
      style={{ height: 40 }}
      variant="contained"
      onClick={generateHash}
    >
      {t('splitlink.new.generateSplitLink')}
    </Button>
  );
}

export default GenerateSplitKeyButton;
