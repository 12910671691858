/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-empty-function */
import * as React from 'react';
import { Auth } from 'aws-amplify';
import { Box, Button, Stack, Typography } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import LanguageSwitcher from '../../../components/languageSwitcher';
import './styles.scss';

function Header() {
  const [loggedIn, setLoggedIn] = useState(false);
  const [name, setName] = useState('');

  const handleSignOut = () => {
    try {
      Auth.signOut();
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        setLoggedIn(true);
        setName(user.attributes.given_name + ' ' + user.attributes.family_name);
      })
      .catch((e) => {
        console.error(e);
        setLoggedIn(false);
      });
  }, [loggedIn]);
  const { t } = useTranslation();

  return (
    <Box
      id="mainHeader"
      width="100%"
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      marginTop={3}
      marginBottom={3}
    >
      <Box className="header-section" />
      <Box
        component="a"
        href="/"
        sx={{ display: 'inline-block', justifySelf: 'center' }}
      >
        <Box
          component="img"
          sx={{
            display: 'block',
            width: 240,
            height: 63,
          }}
          src="/assets/img/splitter-logo.png"
          alt=""
        />
      </Box>

      <Box className="header-section">
        <Stack alignItems="flex-end" spacing={0.5}>
          {loggedIn && (
            <Box
              display="flex"
              gap="0.6rem"
              alignContent="center"
              justifyContent="center"
            >
              <Typography color="white" alignSelf="center">
                {t('home.header.hello')} {name}
              </Typography>
              <Typography color="white" alignSelf="center" fontWeight="bold">
                |
              </Typography>
              <Typography>
                <Button
                  variant="text"
                  type="button"
                  name="logout"
                  className="btn-style"
                  style={{
                    textTransform: 'none',
                    minWidth: 0,
                    padding: '5px 0',
                  }}
                  onClick={() => handleSignOut()}
                >
                  {t('home.header.logout')}
                </Button>
              </Typography>
            </Box>
          )}
          <Typography>
            <Button
              onClick={() => {
                window.open(
                  'https://confluence.vnr.de/display/CON/PX+App+Linksplitter',
                  '_blank'
                );
              }}
              variant="text"
              className="btn-style"
              style={{
                textTransform: 'none',
                padding: 0,
                color: '#fff',
                minWidth: 0,
              }}
              startIcon={
                <InfoIcon style={{ fontSize: '1.1rem', marginTop: 2 }} />
              }
            >
              {t('home.header.documentation')}
            </Button>
          </Typography>
          <LanguageSwitcher />
        </Stack>
      </Box>
    </Box>
  );
}

export default Header;
