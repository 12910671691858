import { store } from 'react-notifications-component';

export const addNotification = (title, message, type = 'danger') => {
  store.addNotification({
    title,
    message,
    type,
    container: 'top-center',
    animationIn: ['animated', 'fadeIn'],
    animationOut: ['animated', 'fadeOut'],
    dismiss: {
      duration: 2500,
    },
  });
};
