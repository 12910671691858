import deleteSplit, { DeleteSplitArgsType } from './splitLinks/onDelete';
import putSplit, { PutSplitArgsType } from './splitLinks/onPut';
import postSplit, { PostSplitArgsType } from './splitLinks/onSave';
import getSplit, { GetSplitArgsType } from './splitLinks/onGet';
import getAllSplits, { GetAllSplitsArgsType } from './splitLinks/onGetAll';

const useApi = (token: string) => {
  const onSaveSplit = (args: PostSplitArgsType) =>
    postSplit({ ...args, sessionToken: token });
  const onDeleteSplit = (args: DeleteSplitArgsType) =>
    deleteSplit({ ...args, sessionToken: token });
  const onPutSplit = (args: PutSplitArgsType) =>
    putSplit({ ...args, sessionToken: token });
  const onGetSplit = (args: GetSplitArgsType) =>
    getSplit({ ...args, sessionToken: token });
  const onGetAllSplits = (args: GetAllSplitsArgsType) =>
    getAllSplits({ ...args, sessionToken: token });

  return {
    onDeleteSplit,
    onPutSplit,
    onSaveSplit,
    onGetSplit,
    onGetAllSplits,
  };
};

export default useApi;
