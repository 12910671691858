const randomIntBetween = (min, max) =>
  Math.floor(Math.random() * (max - min + 1) + min);

export const generateRandomString = (length) => {
  let result = '';
  const chars = '0123456789abcdefghijklmnopqrstuvwxyz';
  const charsLength = chars.length;

  for (let i = 0; i < length; i++) {
    const toAppend = chars[randomIntBetween(0, charsLength - 1)];
    result += toAppend;
  }

  return result;
};
