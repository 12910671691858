import { t } from 'i18next';
import { addNotification } from '../notification';

export const copyToClipboard = (string: string) => {
  navigator.clipboard
    .writeText(string)
    .then(() =>
      addNotification(
        t('splitlink.splitList.copy'),
        t('splitlink.splitList.copied'),
        'info'
      )
    )
    .catch(() =>
      addNotification(
        t('splitlink.splitList.copy'),
        t('splitlink.splitList.copyFailed'),
        'danger'
      )
    );
};
