import React from 'react';
import ReactDOM from 'react-dom';
import './scss/styles.scss';
import { ThemeProvider } from '@mui/material/styles';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import App from './App';
import theme from './styles/theme.ts';
// import reportWebVitals from './reportWebVitals'
import LoadingProvider from './contexts/loading';
import HomeProvider from './contexts/homeContext';
import ModalProvider from './contexts/modal';
import './i18n/index.ts';

function content() {
  return (
    <ThemeProvider theme={theme}>
      <I18nextProvider i18n={i18n}>
        <LoadingProvider>
          <ModalProvider>
            <HomeProvider>
              <App />
            </HomeProvider>
          </ModalProvider>
        </LoadingProvider>
      </I18nextProvider>
    </ThemeProvider>
  );
}
document.getElementById('root');

ReactDOM.render(content(), document.getElementById('app'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals()
