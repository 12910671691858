import { useLayoutEffect, useState } from 'react';

const useResponsivity = () => {
  const [size, setSize] = useState<[number, number]>([0, 0]);

  const useWindowSize = () => {
    useLayoutEffect(() => {
      function updateSize() {
        setSize([window.innerWidth, window.innerHeight]);
      }
      window.addEventListener('resize', updateSize);
      updateSize();
      return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
  };

  useWindowSize();

  const isMobile = size[0] <= 800;

  return [isMobile] as const;
};

export default useResponsivity;
