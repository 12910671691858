import { APIDeleteSplit } from '../api';
import { apiName } from '../../apiName';

export type DeleteSplitArgsType = {
  domain: string;
  split: string;
  sessionToken?: string;
};

const deleteSplit = async ({
  domain,
  split,
  sessionToken,
}: DeleteSplitArgsType) =>
  new Promise((resolve, reject) => {
    if (!sessionToken) {
      reject(new Error('missing token'));
      return;
    }
    const path = `/domains/${domain}/splits/${split}`;
    APIDeleteSplit(apiName + path, sessionToken)
      .then((response) => {
        resolve(response);
      })
      .catch((e) => {
        reject(e);
      });
  });
export default deleteSplit;
