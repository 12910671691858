import React, { createContext, useReducer, useMemo } from 'react';
import { CircularProgress } from '@mui/material';
import { loadingActionType } from '../types';

import { loadingProps, loadingStateType, IChildren } from './types';

const initialState = {
  loading: false,
};

export const LoadingContext = createContext<loadingProps>({
  state: initialState,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  dispatch: () => {},
});

const loadingContextReducer = (
  state: loadingStateType,
  action: loadingActionType
) => {
  switch (action.type) {
    case 'LOADING':
      return { ...state, loading: action.payload };
    default:
      throw new Error('Unhandled action');
  }
};

// eslint-disable-next-line react/function-component-definition
const LoadingProvider = ({ children }: IChildren) => {
  const [state, dispatch] = useReducer(loadingContextReducer, initialState);
  const value = useMemo(() => ({ state, dispatch }), [state, dispatch]);

  return (
    <LoadingContext.Provider value={value}>
      {children}
      {state.loading && (
        <div className="loading-overlay">
          <CircularProgress />
        </div>
      )}
    </LoadingContext.Provider>
  );
};

export default LoadingProvider;
