/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react';

type PropTypes = {
  items: any;
  itemsPerPage?: number;
};

type Pagination = {
  currentItems: [];
  onPageChange: (currPage: number) => void;
  onItemsPerPageChange: (value: number) => void;
  totalPages: number;
  currentPage: number;
  itemsPerPage: number;
};

export function usePagination({
  items,
  itemsPerPage: itemsPerPageProps = 15,
}: PropTypes): Pagination {
  const [totalPages, setTotalPages] = useState<number>(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentItems, setCurrentItems] = useState<[]>([]);
  const [itemsPerPage, setItemsPerPage] = useState(itemsPerPageProps);

  useEffect(() => {
    const endOffset = (currentPage - 1) * itemsPerPage + itemsPerPage;
    const startOffset = endOffset - itemsPerPage;
    if (items) {
      setTotalPages(Math.ceil(items.length / itemsPerPage));
    } else {
      setTotalPages(1);
    }

    setCurrentItems(
      items.length > itemsPerPage
        ? items.slice(startOffset, endOffset)
        : items.slice(0, items.length)
    );
  }, [items, currentPage, itemsPerPage]);

  useEffect(() => {
    if (currentPage > totalPages) {
      setCurrentPage(totalPages);
    }

    setCurrentPage(1);
  }, [items.length, currentPage, totalPages]);

  const handlePageChange = (currPage: number) => {
    setCurrentPage(currPage);
  };

  const handleItemsPerPageChange = (value: number) => {
    setItemsPerPage(value)
  }

  return {
    currentItems,
    onPageChange: handlePageChange,
    totalPages,
    currentPage,
    onItemsPerPageChange: handleItemsPerPageChange,
    itemsPerPage
  };
}
