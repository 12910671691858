import React, { ReactElement } from 'react';
import Tooltip, { TooltipProps } from '@mui/material/Tooltip';

type Props = {
  label: TooltipProps['title'];
  placement?: TooltipProps['placement'];
  children: ReactElement;
};

function TooltipComponent({ label, placement = 'bottom', children }: Props) {
  return (
    <Tooltip title={label} placement={placement}>
      {children}
    </Tooltip>
  );
}

export default TooltipComponent;
