/* eslint-disable no-self-compare */
/* eslint-disable no-constant-condition */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useEffect, useState } from 'react';
import SelectDomain from './components/SelectDomain';
import { useLoading } from '../../hooks/useLoading';
import { APIFetchDomains } from '../../api/api';

import { apiName } from '../../apiName';
import useAuth from '../../hooks/useAuth';

function Domains() {
  const [allDomainsData, setAllDomainsData] = useState([]);
  const [domainName, setDomainName] = useState('');
  const loading = useLoading();
  const dispatchLoading = loading.dispatch;
  const { token: sessionToken, isAuthenticated } = useAuth();

  const onChange = (domain: string) => {
    if (domain !== domainName) {
      setDomainName(domain);
    }
  };

  const getDomains = useCallback(async () => {
    dispatchLoading({ type: 'LOADING', payload: true });
    return new Promise((resolve) => {
      const path = '/domains';

      APIFetchDomains(apiName + path, sessionToken).then((response: any) => {
        dispatchLoading({ type: 'LOADING', payload: false });
        resolve(response);
      });
    });
  }, [dispatchLoading, sessionToken]);

  const getDomainsData = useCallback(() => {
    dispatchLoading({ type: 'LOADING', payload: true });
    getDomains()
      .then((response: any) => {
        if (typeof response !== 'undefined') {
          setAllDomainsData(response.body);
          dispatchLoading({ type: 'LOADING', payload: false });
        }
      })
      .catch(console.error);
  }, [getDomains, dispatchLoading]);

  useEffect(() => {
    if (isAuthenticated) {
      getDomainsData();
    }
  }, [getDomainsData, isAuthenticated]);

  return <SelectDomain domains={allDomainsData} onChange={onChange} />;
}

export default Domains;
