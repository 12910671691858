/* eslint-disable react/function-component-definition */
/* eslint-disable @typescript-eslint/no-empty-function */
import React, { createContext, useReducer, useMemo } from 'react';
import { IChildren, HomeInitialStateType } from './types';
import { HomeActionType } from '../types';

type ContextProps = {
  state: HomeInitialStateType;
  dispatch: (action: HomeActionType) => void;
};

const initialHomeState = {
  name: '',
  loggedIn: false,
};

export const HomeContext = createContext<ContextProps>({
  state: initialHomeState,
  dispatch: () => {},
});

const homeContextReducer = (
  state: HomeInitialStateType,
  action: HomeActionType
) => {
  switch (action.type) {
    case 'CHANGE_NAME':
      return {
        ...state,
        name: action.payload.name,
      };
    case 'CHANGE_IS_LOGGED':
      return {
        ...state,
        loggedIn: action.payload.loggedIn,
      };
    default:
      throw new Error('Unhandled action');
  }
};

const HomeProvider = ({ children }: IChildren) => {
  const [state, dispatch] = useReducer(homeContextReducer, initialHomeState);

  const value = useMemo(() => ({ state, dispatch }), [state, dispatch]);

  return <HomeContext.Provider value={value}>{children}</HomeContext.Provider>;
};

export default HomeProvider;
