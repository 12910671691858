/* eslint-disable react/jsx-no-useless-fragment */
import React, { useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import IconButton from '@mui/material/IconButton';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import {
  Box,
  Button,
  MenuItem,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
 Select, 
 Typography} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import { usePagination } from '../../../../hooks/usePagination';
import Pagination from '../../../../components/Pagination';
import Tooltip from '../../../../components/Tooltip';
import useQuery from '../../../../hooks/useQuery';
import { formatDateEpoch } from '../../../../utils/formatter/date';
import { formatUserInfo } from '../../../../utils/formatter/string';
import './styles.scss';
import SearchInput from '../../../../components/SearchInput';
import { SplitLinkDataType } from '../../../../types/splitLinks';
import useResponsivity from '../../../../hooks/useResponsivity';

type PropsType = {
  splitlinks: SplitLinkDataType[];
  domainName: string;
  sortDirection: boolean;
  sortType: 'split' | 'user' | 'changedAt';
  searchValue: string;
  onCopy: (splitlink: string) => void;
  onSelect: (splitlink: string) => void;
  onDelete: (splitlink: string) => void;
  onSort: (sortType: 'split' | 'user' | 'changedAt') => void;
  onChangeSearch: (value: string) => void;
};

function SplitlinksTable({
  splitlinks,
  domainName,
  sortDirection,
  sortType,
  searchValue,
  onCopy,
  onSelect,
  onDelete,
  onSort,
  onChangeSearch,
}: PropsType) {
  const [lastPage, setLastPage] = React.useState(1);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const query = useQuery();
  const { currentItems, onPageChange, totalPages, currentPage, itemsPerPage, onItemsPerPageChange } = usePagination(
    { items: splitlinks }
  );
  const { current } = useRef<{ pageLoaded: boolean }>({ pageLoaded: false });
  const pathPage = parseInt(query.get('page') as string, 10);
  const splitKeyId = query.get('splitlink');
  const [isMobile] = useResponsivity();

  useEffect(() => {
    if (pathPage && !Number.isNaN(pathPage) && pathPage !== lastPage) {
      onPageChange(pathPage);
      setLastPage(pathPage);
    }
  }, [pathPage, onPageChange, lastPage]);

  useEffect(() => {
    if (splitKeyId && !current.pageLoaded && currentItems.length > 0) {
      current.pageLoaded = true;
      onSelect(splitKeyId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentItems]);

  const handlePageChange = useCallback(
    (page: number) => {
      onPageChange(page);
      navigate(`/domains/${domainName}/?page=${page}`);
    },
    [navigate, domainName, onPageChange]
  );

  useEffect(() => {
    if (!Number.isNaN(pathPage) && currentPage !== pathPage) {
      handlePageChange(currentPage);
    }
  }, [currentPage, pathPage, handlePageChange]);

  const StyledTableRow = React.useMemo(
    () =>
      styled(TableRow)(() => ({
        borderBottom: '1px solid rgba(224, 224, 224, 1)',
        position: 'relative',

        '&:nth-of-type(odd)': {
          backgroundColor: '#f7fafc',
        },
        '&:first-of-type': {
          borderTop: '1px solid rgba(224, 224, 224, 1)',
        },
        '&:last-child td, &:last-child th': {
          border: 0,
        },
        a: {
          textDecoration: 'none',
        },
        'a:hover': {
          textDecoration: 'underline',
        },
        '&:hover': {
          backgroundColor: '#e6e6e6',
        },
      })),
    []
  );

  const StyledTableCell = React.useMemo(
    () =>
      styled(TableCell)(() => ({
        borderBottom: 'none',
        padding: 0,
      })),
    []
  );

  return (
    <>
      <Table style={{ width: '100%', tableLayout: 'fixed' }}>
        <TableHead>
          <TableRow>
            <StyledTableCell style={{ width: '40%' }} />
            <StyledTableCell style={{ width: '27%' }} />
            <StyledTableCell style={{ width: '19%' }} />
            <StyledTableCell />
          </TableRow>
          {isMobile ? (
            <>
              <TableRow>
                <StyledTableCell colSpan={4}>
                  <SearchInput
                    value={searchValue}
                    onChange={onChangeSearch}
                    style={{ flex: 1 }}
                  />
                </StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell colSpan={4}>
                  <Box display="flex" className="pagination-wrapper">
                    <Pagination
                      onPageChange={handlePageChange}
                      totalPages={totalPages}
                      currentPage={currentPage}
                      listLength={splitlinks.length}
                    />
                  </Box>
                </StyledTableCell>
              </TableRow>
            </>
          ) : (
            <TableRow>
              <StyledTableCell size="medium">
                <SearchInput
                  value={searchValue}
                  onChange={onChangeSearch}
                  style={{ flex: 1 }}
                />
              </StyledTableCell>
              <StyledTableCell colSpan={3}>
                <Box display="flex" className="pagination-wrapper">
                  <Pagination
                    onPageChange={handlePageChange}
                    totalPages={totalPages}
                    currentPage={currentPage}
                    listLength={splitlinks.length}
                  />
                </Box>
              </StyledTableCell>
            </TableRow>
          )}
          <TableRow>
            <TableCell size="medium">
              <Button
                onClick={() => onSort('split')}
                size="small"
                color="inherit"
                disableRipple
                variant="text"
                style={{ textTransform: 'none' }}
              >
                <Box display="flex" alignItems="center">
                  <span>Splitlink</span>
                  {sortType === 'split' && (
                    <>
                      {sortDirection ? (
                        <ArrowDownward fontSize="small" />
                      ) : (
                        <ArrowUpward fontSize="small" />
                      )}
                    </>
                  )}
                </Box>
              </Button>
            </TableCell>
            <TableCell size="medium" className="desktop-content">
              <Button
                onClick={() => onSort('user')}
                size="small"
                color="inherit"
                disableRipple
                variant="text"
                style={{ textTransform: 'none' }}
              >
                <Box display="flex" alignItems="center">
                  <span>{t('splitlink.splitLinksTable.header.user')}</span>
                  {sortType === 'user' && (
                    <>
                      {sortDirection ? (
                        <ArrowDownward fontSize="small" />
                      ) : (
                        <ArrowUpward fontSize="small" />
                      )}
                    </>
                  )}
                </Box>
              </Button>
            </TableCell>
            <TableCell size="medium" colSpan={1} className="desktop-content">
              <Button
                onClick={() => onSort('changedAt')}
                size="small"
                color="inherit"
                disableRipple
                variant="text"
                style={{ textTransform: 'none' }}
              >
                <Box display="flex" alignItems="center">
                  <span>{t('splitlink.splitLinksTable.header.changedAt')}</span>
                  {sortType === 'changedAt' && (
                    <>
                      {sortDirection ? (
                        <ArrowDownward fontSize="small" />
                      ) : (
                        <ArrowUpward fontSize="small" />
                      )}
                    </>
                  )}
                </Box>
              </Button>
            </TableCell>

            <TableCell size="small">
              <></>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {currentItems.map(
            ({ domain, split, description, user, changedAt }) => (
              <StyledTableRow key={split + changedAt}>
                <TableCell colSpan={isMobile ? 3 : undefined}>
                  <Box className="splitLink-column">
                    <a
                      href={'https://' + domain + '/' + split}
                      className="split-link"
                      target="_blank"
                      rel="noreferrer"
                      style={{ fontSize: 16 }}
                    >
                      {domain + '/'}
                      {split}
                    </a>

                    <span className="light-text description">
                      {description}
                    </span>
                  </Box>
                </TableCell>
                <TableCell size="medium" className="desktop-content">
                  <p>{formatUserInfo(user)}</p>
                </TableCell>
                <TableCell size="medium" className="desktop-content">
                  <p>{formatDateEpoch(changedAt)}</p>
                </TableCell>
                <TableCell className="small-col" size="small">
                  <Tooltip label={t('splitlink.splitLinksTable.copyURL')}>
                    <IconButton
                      aria-label="copy"
                      onClick={() => onCopy('https://' + domain + '/' + split)}
                      className="btn-with-icon"
                      data-tip
                      color="primary"
                      data-for="copySplit"
                    >
                      <FileCopyIcon style={{ fontSize: '1.5rem' }} />
                    </IconButton>
                  </Tooltip>
                  <Tooltip label={t('splitlink.splitLinksTable.editSplit')}>
                    <IconButton
                      aria-label="edit"
                      onClick={() => onSelect(split)}
                      className="btn-with-icon"
                      data-tip
                      data-for="editSplit"
                      color="primary"
                    >
                      <EditIcon style={{ fontSize: '1.5rem' }} />
                    </IconButton>
                  </Tooltip>
                  <Tooltip label={t('splitlink.splitLinksTable.deleteSplit')}>
                    <IconButton
                      aria-label="delete"
                      onClick={() => onDelete(split)}
                      className="btn-with-icon"
                      data-tip
                      data-for="deleteSplit"
                      color="primary"
                    >
                      <DeleteIcon style={{ fontSize: '1.5rem' }} />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </StyledTableRow>
            )
          )}
        </TableBody>
      </Table>
      <Box display="flex" paddingY={2} justifyContent="center">
        {splitlinks.length === 0 && t('splitlink.splitList.notFound')}
      </Box>
      <Box display="flex" className="pagination-wrapper" alignItems="baseline">
        <Pagination
          onPageChange={handlePageChange}
          totalPages={totalPages}
          currentPage={currentPage}
          withItemsPerPage
          itemsPerPage={itemsPerPage}
          listLength={splitlinks.length}
          onItemsPerPageChange={onItemsPerPageChange}
        />        
      </Box>
    </>
  );
}

export default SplitlinksTable;
