import { apiName } from '../../apiName';
import { APIFetchAllSplits } from '../api';

export type GetAllSplitsArgsType = {
  domain: string;
  sessionToken?: string;
};
async function getAllSplits({ domain, sessionToken }: GetAllSplitsArgsType) {
  return new Promise((resolve, reject) => {
    if (!sessionToken) {
      reject(new Error('missing token'));
      return;
    }
    const path = `/domains/${domain}/splits`;
    APIFetchAllSplits(apiName + path, sessionToken)
      .then((response) => {
        resolve(response);
      })
      .catch((e) => {
        reject(e);
      });
  });
}

export default getAllSplits;
