/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Box, MenuItem, Pagination as PaginationMaterial, Typography, Select } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Margin } from '@mui/icons-material';

type PropsType = {
  totalPages: number;
  onPageChange: any;
  currentPage: number;
  onItemsPerPageChange?: (value: number) => void;
  itemsPerPage?: number;
  listLength: number;
  withItemsPerPage?: boolean
};

function Pagination({ totalPages, onPageChange, currentPage, itemsPerPage, listLength, onItemsPerPageChange, withItemsPerPage = false }: PropsType) {
  const { t } = useTranslation();
  
  if (listLength < 5) {
    return null;
  }

  return (
    <Box display="flex" alignItems="baseline">  
      {withItemsPerPage && (
        <>
          <Typography sx={{ marginRight: "15px" }}>{t('splitlink.splitList.itemsPerPage')}</Typography>
          <Select sx={{ "& .MuiSelect-select": { padding: "8px 0 8px 8px" } }} value={itemsPerPage} onChange={({ target }) => onItemsPerPageChange && onItemsPerPageChange(target.value as number)}>
                <MenuItem value={listLength}>{t('splitlink.splitList.all')}</MenuItem>
                <MenuItem value={5}>5</MenuItem>
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={15}>15</MenuItem>
                <MenuItem value={20}>20</MenuItem>
          </Select>
        </>
      )}  
      <PaginationMaterial
        style={{ paddingTop: 16, marginLeft: 40 }}
        count={totalPages}
        onChange={(_, page) => onPageChange(page)}
        page={currentPage}
      />
    </Box>
  );
}

export default Pagination;
