import { APIPostSplit } from '../api';
import { apiName } from '../../apiName';
import { SplitTargetType } from '../../types/splitLinks';

export type PostSplitArgsType = {
  domain: string;
  splitName: string;
  splitData: SplitTargetType[];
  splitDescription: string;
  sessionToken?: string;
};
export default async function postSplit({
  domain,
  splitName,
  splitData,
  splitDescription,
  sessionToken,
}: PostSplitArgsType) {
  return new Promise((resolve, reject) => {
    if (!sessionToken) {
      reject(new Error('missing token'));
      return;
    }
    const path = `/domains/${domain}/splits/${splitName}`;
    APIPostSplit(
      apiName + path,
      splitName,
      splitData.map(({ id, ...rest }) => rest),
      splitDescription,
      domain,
      sessionToken
    )
      .then((response) => {
        resolve(response);
      })
      .catch((e) => {
        reject(e);
      });
  });
}
