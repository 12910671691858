import { Box } from '@mui/material';
import React from 'react';
import { Route, Routes, BrowserRouter } from 'react-router-dom';
import Welcome from '../components/Welcome';
import Domains from '../pages/Domains';
import Home from '../pages/Home';
import Header from '../pages/Home/Header';
import Links from '../pages/Links';

function LinkSplitterRoutes() {
  return (    
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Welcome />} />
            <Route path="/home" element={<StyledRoute element={Home} />} />
            <Route path="/domains/" element={<StyledRoute element={Domains} />} />
            <Route path="/domains/:domainId/" element={<StyledRoute element={Links} />} />
            <Route path="/domains/:domainId/:splitKeyId" element={<StyledRoute element={Links} />} />
          </Routes>
        </BrowserRouter>
  );
}

function StyledRoute({ element: Element }: Record<string, any>) {
  return (
    <Box style={{
      marginBottom: "3em",
      maxWidth: "1400px",
      margin: "0 auto"
    }}>
        <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        sx={{ boxSizing: 'border-box', maxWidth: '100%' }}
      >
        <Header />
        <Box
          id="mainBody"
          width="100%"
          justifyContent="center"
          bgcolor="white"
          borderRadius="0.3rem"
          margin={0}
          p="30px 50px 0px 50px"
          boxShadow={2}
          sx={{ boxSizing: 'border-box', maxWidth: '100%', paddingBottom: 4 }}
        >
          <Element />
        </Box>
      </Box>
    </Box>
    );
}

export default LinkSplitterRoutes;
