import { SplitDomainsType } from '../../types/domains';
import {
  DomainDataType,
  SortType,
  SplitLinkDataType,
} from '../../types/splitLinks';

export const filter = (array: SplitLinkDataType[]) => ({
  byText: (text: string) => {
    if (text === '') return array;
    return array.filter((item) => {
      const splitProp = item.split ?? '';
      const descriptionProp = item.description ?? '';
      const userProp = item.user ?? '';

      return (
        splitProp.toLowerCase().includes(text.toLowerCase()) ||
        descriptionProp.toLowerCase().includes(text.toLowerCase()) ||
        userProp.toLowerCase().includes(text.toLowerCase())
      );
    });
  },
  byName: (text: string) => {
    if (text === '') return array;
    return array.filter(
      (item) => item.split.toLowerCase() === text.toLowerCase()
    );
  },
});

export const filterDomains = (array: SplitDomainsType[]) => ({
  byText: (text: string) => {
    if (text === '') return array;
    return array.filter((item) => item.domain.includes(text.toLowerCase()));
  },
});

const sortSplitlinksFunc = (
  array: SplitLinkDataType[],
  sortType: 'split' | 'changedAt' | 'user'
) => {
  const sorted = array.sort((a, b) => {
    let aLower;
    let bLower;
    if (sortType === 'changedAt') {
      const dateA = a[sortType] ?? 0;
      const dateB = b[sortType] ?? 0;
      aLower = dateA;
      bLower = dateB;
    } else {
      const textA = a[sortType] ?? '';
      const textB = b[sortType] ?? '';
      aLower = textA.toUpperCase();
      bLower = textB.toUpperCase();
    }
    // eslint-disable-next-line no-nested-ternary
    return aLower < bLower ? -1 : aLower > bLower ? 1 : 0;
  });

  return sorted;
};

const sortDomainsFunc = (array: DomainDataType[]) => {
  const sorted = array.sort((a, b) => {
    const textA = a.domain ?? '';
    const textB = b.domain ?? '';
    const aLower = textA.toUpperCase();
    const bLower = textB.toUpperCase();

    // eslint-disable-next-line no-nested-ternary
    return aLower < bLower ? -1 : aLower > bLower ? 1 : 0;
  });

  return sorted;
};

export const sortSplitlinks = (
  array: SplitLinkDataType[],
  sortType: SortType
) => ({
  alphabetically: () => {
    const sorted = sortSplitlinksFunc(array, sortType);
    return sorted;
  },
  alphabeticallyDesc: () => {
    const sorted = sortSplitlinksFunc(array, sortType);
    return sorted.reverse();
  },
});

export const sortDomains = (array: DomainDataType[]) => ({
  alphabetically: () => {
    const sorted = sortDomainsFunc(array);
    return sorted;
  },
  alphabeticallyDesc: () => {
    const sorted = sortDomainsFunc(array);
    return sorted.reverse();
  },
});
