/* eslint-disable react/jsx-no-useless-fragment */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Box,
  styled,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  TableHead,
  Button,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useNavigate } from 'react-router-dom';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import { usePagination } from '../../../../hooks/usePagination';
import Pagination from '../../../../components/Pagination';
import useQuery from '../../../../hooks/useQuery';
import SearchInput from '../../../../components/SearchInput';
import { filterDomains, sortDomains } from '../../../../utils/arrays';

function SelectDomain({ domains, onChange }) {
  const { t } = useTranslation();

  const [searchValue, setSearchValue] = useState('');
  const [sortedColumn, setSortedColumn] = useState([]);
  const [sortDirection, setSortDirection] = useState(false);
  const [sortType, setSortType] = useState('domain');

  const navigate = useNavigate();
  const query = useQuery();
  const pageId = query.get('page');
  const { currentItems, currentPage, onPageChange, totalPages, itemsPerPage, onItemsPerPageChange } = usePagination(
    { items: sortedColumn }
  );

  const handleSort = useCallback(
    (sortTypeParams) => {
      if (sortType !== sortTypeParams) {
        setSortType(sortTypeParams);
        setSortDirection(false);
      } else {
        setSortDirection((previous) => !previous);
      }
    },
    [sortType]
  );

  useEffect(() => {
    setSortedColumn(filterDomains(domains).byText(searchValue));
  }, [searchValue, domains]);

  useEffect(() => {
    if (sortedColumn.length > 0) {
      if (sortDirection) {
        setSortedColumn((prev) =>
          sortDomains([...prev], sortType, 'domains').alphabeticallyDesc()
        );
      } else if (!sortDirection) {
        setSortedColumn((prev) =>
          sortDomains([...prev], sortType, 'domains').alphabetically()
        );
      }
    }
  }, [sortDirection, sortType, sortedColumn.length]);

  useEffect(() => {
    if (pageId) {
      onPageChange(parseInt(pageId, 10));
    }
  }, [pageId, onPageChange]);

  const handlePageChange = (page) => {
    navigate(`/domains/?page=${page}`);
  };

  const onSelectDomain = (domain) => {
    onChange(domain);
    navigate(`/domains/${domain}/?page=${currentPage}`);
  };

  const StyledTableRow = React.useMemo(
    () =>
      styled(TableRow)(() => ({
        borderBottom: '1px solid rgba(224, 224, 224, 1)',
        cursor: 'pointer',

        '&:nth-of-type(odd)': {
          backgroundColor: '#f7fafc',
        },
        '&:first-of-type': {
          borderTop: '1px solid rgba(224, 224, 224, 1)',
        },
        '&:last-child td, &:last-child th': {
          border: 0,
        },
        td: {
          '&:hover': { backgroundColor: '#e6e6e6' },
        },
      })),
    []
  );

  const StyledTableCell = useMemo(
    () =>
      styled(TableCell)(() => ({
        borderBottom: 'none',
        padding: 0,
      })),
    []
  );

  if (domains.length === 0) {
    return null;
  }

  return (
    <div>
      <Typography
        variant="h3"
        style={{ fontWeight: '500', fontSize: 28, paddingLeft: 12, paddingTop: 12 }}
      >
        {t('splitlink.selectDomain.splitdomains')}
      </Typography>

      <Table>
        <TableHead>
          <TableRow
            sx={{
              display: 'flex',
              width: '100%',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <StyledTableCell size="medium" colSpan={1} sx={{ width: '50% ' }}>
              <SearchInput
                value={searchValue}
                onChange={setSearchValue}
                style={{ flex: 1 }}
                c
              />
            </StyledTableCell>
            <StyledTableCell size="medium" colSpan={1}>
              <Box display="flex" className="pagination-wrapper">
                <Pagination
                  onPageChange={handlePageChange}
                  totalPages={totalPages}
                  currentPage={currentPage}
                  listLength={sortedColumn.length}
                />
              </Box>
            </StyledTableCell>
          </TableRow>
          <TableRow>
            <TableCell size="medium">
              <Button
                onClick={() => {
                  handleSort('domain');
                }}
                size="small"
                color="inherit"
                disableRipple
                variant="text"
                style={{ textTransform: 'none' }}
              >
                <Box display="flex" alignItems="center">
                  <span>Splitdomain</span>
                  {sortType === 'domain' && (
                    <>
                      {sortDirection ? (
                        <ArrowDownward fontSize="small" />
                      ) : (
                        <ArrowUpward fontSize="small" />
                      )}
                    </>
                  )}
                </Box>
              </Button>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {currentItems.map((domain) => (
            <StyledTableRow key={domain.domain} sx={{ width: '100%' }}>
              <TableCell
                sx={{
                  display: 'flex',
                  width: '100%',
                }}
                onClick={() => onSelectDomain(domain.domain)}
              >
                <ArrowForwardIcon />
                <Typography style={{ marginLeft: '1em' }}>
                  {domain.domain}
                </Typography>
              </TableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
      <Box sx={{ display: 'flex' }} justifyContent="flex-end">
        <Pagination
          currentPage={currentPage}
          onPageChange={handlePageChange}
          totalPages={totalPages}
          withItemsPerPage
          itemsPerPage={itemsPerPage}
          onItemsPerPageChange={onItemsPerPageChange}
          listLength={sortedColumn.length}
        />
      </Box>
    </div>
  );
}

export default SelectDomain;
