/* eslint-disable no-constant-condition */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console */
import React, { useEffect } from 'react';
import { Box, Button, CardContent, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import InfoIcon from '@mui/icons-material/InfoOutlined';

import useAuth from '../../hooks/useAuth';
import { useLoading } from '../../hooks/useLoading';
import logo from "../../img/px-splitter-logo.jpg";
import LanguageSwitcher from '../languageSwitcher';
import {ReactComponent as MicrosoftLogo} from '../../img/MSFT.svg';
import { styles } from "./styles";


function Welcome() {
  const loading = useLoading();
  const dispatchLoading = loading.dispatch;

  const { handleLogin, isAuthenticated } = useAuth();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const doLogin = async () => {
    handleLogin();
    dispatchLoading({ type: 'LOADING', payload: true });
    setTimeout(() => {
      dispatchLoading({ type: 'LOADING', payload: false });
    }, 2000);
  };

  useEffect(() => {
    if (isAuthenticated) {
      navigate('/domains/');
    }
  }, [isAuthenticated]);

  return (
    <Box>
      <Box sx={styles.wrapper}>
        <Box margin="auto 0">
            <Box sx={styles.card}>
              <Box sx={styles.imageWrapper}>
                <img src={logo} alt="" style={styles.imageStyle} />
              </Box>
              <CardContent style={{ padding: 0 }}>
                <Box sx={styles.cardContentWrapper}>
                  <Typography sx={styles.cardTitle} variant="h5" component="div">
                    {t('welcome.welcomeToPX')}
                  </Typography>
                  <Typography sx={styles.cardText} variant="body2" component="div">
                    {t('welcome.youADUser')}
                  </Typography>
                  <Button sx={styles.cardButton} variant="contained" startIcon={<MicrosoftLogo style={{height:15, width:15}} />} onClick={doLogin}>{t('welcome.login')}</Button>
                </Box>
              </CardContent>
              <Box sx={styles.languageSwitcherWrapper}>
                <LanguageSwitcher />
              </Box>
              <Box sx={styles.documentationIconWrapper}>
                  <Button sx={styles.documentationIconButton}
                    onClick={() => {
                      window.open(
                        'https://confluence.vnr.de/display/CON/PX+App+Linksplitter',
                        '_blank'
                      );
                    }}
                  >
                    <InfoIcon style={{ fontSize: '2.0rem' }}/>
                  </Button>
              </Box>
            </Box>
        </Box>
      </Box> 
    </Box>
         
  );
}

export default Welcome;
