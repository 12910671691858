export const linkValidationSettings = {
  splitLink: {
    name: {
      minLength: 3,
      maxLength: 100,
      regex: /[a-zA-Z0-9-]/gi,
    },
    description: {
      minLength: 3,
      maxLength: 100,
    },
    splitTarget: {
      url: {
        minLength: 3,
        maxLength: 2048,
      },
    },
  },
};
