import React, { CSSProperties } from 'react';
import { useTranslation } from 'react-i18next';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { Button, TextField } from '@mui/material';

type PropsType = {
  value: string;
  onChange: (value: string) => void;
  onSubmit?: (value: string) => void;
  style?: CSSProperties;
};
function SearchInput({ value = '', onChange, onSubmit, style }: PropsType) {
  const { t } = useTranslation();
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) onChange(e.target.value);
  };
  return (
    <div style={{ display: 'flex' }}>
      <TextField
        style={{ ...style }}
        type="text"
        variant="outlined"
        value={value}
        onChange={handleChange}
        label={t('splitlink.splitList.search')}
        size="small"
      />
      {onSubmit && (
        <Button className="btn-style add-btn" onClick={() => onSubmit(value)}>
          <SearchOutlinedIcon />
        </Button>
      )}
    </div>
  );
}

export default SearchInput;
